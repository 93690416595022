import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import ToasterGen from "../../helper/Toaster";
import { callsData } from "../../redux/dataStore/callsSlice";
import moment from "moment";
import { DEFAULT_COOKIE_GETTER } from "../../helper/Cookie";
import getApi from "../../helper/getApi";

// Define types
interface TimerData {
  [key: string]: string;
}

const Main = () => {
  const [userList, setUserList] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]); // Changed to array

  const dataCalls = useSelector((state: RootState) => state.calls);
  const [elapsedTimes, setElapsedTimes] = useState<TimerData>({});
  const dispatch = useDispatch<AppDispatch>();

  const handleUserChange = (user: string) => {
    setSelectedUsers((prev) => 
      prev.includes(user) ? prev.filter(u => u !== user) : [...prev, user]
    );
  };

  useEffect(() => {
    async function fetchUsers() {
      try {
        const activationToken = await DEFAULT_COOKIE_GETTER('access_token');
        const headers = {
          'Authorization': 'Bearer ' + activationToken,
          'Accept': '*/*',
          'Access-Control-Allow-Origin': true,
        };

        let dataEmail = await DEFAULT_COOKIE_GETTER('user');
        dataEmail = JSON.parse(dataEmail || '{}').email;
        const response: any = await getApi('auth/get_team_by_email', dataEmail, headers);
        setUserList(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchUsers();
  }, []);

  const getElapsedTime = (startTime: string, previousElapsedTime: string = "00:00:00") => {
    const start = moment(startTime);
    const now = moment();
    const duration = moment.duration(now.diff(start));
    const previousDuration = moment.duration(previousElapsedTime);
    const totalDuration = duration.add(previousDuration);

    return `${String(totalDuration.hours()).padStart(2, '0')}:${String(totalDuration.minutes()).padStart(2, '0')}:${String(totalDuration.seconds()).padStart(2, '0')}`;
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const dataEmail = await DEFAULT_COOKIE_GETTER("user");
  
      dispatch(callsData({
        email: JSON.parse(dataEmail || "{}").email
      }));

      // Update elapsed times every second
      setElapsedTimes((prevTimes) => {
        const updatedTimes: TimerData = { ...prevTimes };
        Object.keys(updatedTimes).forEach((userId) => {
          updatedTimes[userId] = getElapsedTime(prevTimes[userId], updatedTimes[userId]);
        });
        return updatedTimes;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const getStatusColor = useCallback((status: string, userId: string, timer: string) => {
    if (status === "open" || (status === 'open' && timer)) {
      try {
        const [hours, minutes, seconds] = timer.split(":").map(Number);
        const idleInSeconds = hours * 3600 + minutes * 60 + seconds;
        const maxIdleTimeInSeconds = 30 * 60; // 30 minutes in seconds

        if (idleInSeconds > maxIdleTimeInSeconds) {
          return "bg-red-500";
        }
      } catch (e) {
        return "bg-blue-500";
      }
    }

    switch (status) {
      case "idle":
        return "bg-blue-400";
      case "inactive":
        return "bg-gray-300";
      case "active":
        return "bg-green-500";
      case "open":
        return "bg-blue-500";
      case "inuse":
        return "bg-green-500";
      default:
        return "bg-gray-300";
    }
  }, []);

  const numColumns = dataCalls && dataCalls.calls ? dataCalls.calls.length : 1;
  const maxColumns = 7; // Optionally set a max number of columns
  const gridColumns = Math.min(numColumns, maxColumns);

  return (
    <>
      <ToasterGen />
      <div className="flex mt-4 p-10">
        <div className="relative">
          <label className="mr-2">Select Users:</label>
          <div className="flex flex-wrap">
  {userList.map((user) => (
    <label key={user} className="flex items-center mr-4">
      <input
        type="checkbox"
        checked={selectedUsers.includes(user)}
        onChange={() => handleUserChange(user)}
        className="mr-2"
      />
      {user}
    </label>
  ))}
</div>

        </div>
      </div>

      <div>
        <div>
          <h2 className="font-semibold text-2xl">User's Live Dashboard</h2>
          <h5 className="font-semibold text-gray-400">Represents the Timer Running for each User and the Activity (In Use, Idle, Progressing)</h5>
        </div>
        <div
          className="grid w-full gap-4 mt-20"
          style={{ gridTemplateColumns: `repeat(${gridColumns}, 1fr)` }}
        >
          {dataCalls && dataCalls.calls && dataCalls.calls.map((item: any, index: number) => (
            selectedUsers.includes(item.user) ? (
              <div key={index} className="flex flex-col bg-white border-2 border-solid shadow-lg p-4">
                <div className="ml-2 md:ml-4">
                  <p className="text-line font-dmsans text-lg font-semibold">
                    {item.first_name + " " + item.last_name}
                  </p>
                </div>
                <div className="lg:flex items-center justify-center md:justify-start mb-2">
                  <div className={`m-auto uppercase ${getStatusColor(item.presence, item.uid, item.timer)}`}>
                    <p className="p-8 text-white font-semibold break-all text-xs">
                      {item.presence === "open" ? 'IDLE' : item.presence === "inactive" ? "Offline" : item.presence}
                    </p>
                  </div>
                </div>
                {item.presence === "open" && (
                  <div className="ml-2 md:ml-4">
                    <p className="text-line font-dmsans text-md font-normal text-gray-400">
                      Idle Time: {item.timer}
                    </p>
                  </div>
                )}
              </div>
            ) : null
          ))}
        </div>
      </div>
    </>
  );
};

export default Main;
